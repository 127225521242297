var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        _vm._b(
          {
            attrs: {
              title: _vm.title,
              centered: _vm.centered,
              "dialog-style": _vm.dialogstyle,
              maskClosable: _vm.maskClosable,
              width: _vm.width,
              showmodal: _vm.showmodal,
              id: _vm.idmodal,
              "on-ok": "handleOk",
              closable: false,
              keyboard: false,
              forceRender: _vm.forceRender
            },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          "a-modal",
          _vm.$attrs,
          false
        ),
        [
          _c("template", { slot: "footer" }, [_vm._t("footermodal")], 2),
          _vm._t("contentmodal")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }