var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("common.detail-text", {
          text: _vm.$t("lbl_warehouse_transfer")
        }),
        loading: _vm.loading
      }
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            model: _vm.formState,
            "wrapper-col": { span: 12 },
            "label-col": { span: 6 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_document_number"),
                prop: "documentNumber"
              }
            },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(" " + _vm._s(_vm.formState.documentNumber || "-") + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_from"), prop: "sourceLocation" } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(" " + _vm._s(_vm.formState.sourceLocation || "-") + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_to"), prop: "destinationLocation" } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(
                  " " + _vm._s(_vm.formState.destinationLocation || "-") + " "
                )
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(" " + _vm._s(_vm._f("date")(_vm.formState.date)) + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_note"), prop: "notes" } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(" " + _vm._s(_vm.formState.notes || "-") + " ")
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_attachment"), prop: "attachment" } },
            [
              _vm.formState.attachment
                ? _c(
                    "RouterLink",
                    {
                      attrs: {
                        to: _vm.formState.attachment,
                        target: "_blank",
                        download: _vm.formState.documentNumber + "-attachment"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.formState.attachment) + " ")]
                  )
                : _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(" " + _vm._s("-") + " ")
                  ])
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_status"), prop: "state" } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(" " + _vm._s(_vm.toTitlecase(_vm.formState.state)) + " ")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("a-table", {
            attrs: {
              "data-source": _vm.formState.transferLines,
              "row-key": "id",
              columns: _vm.columns,
              size: "small",
              "row-class-name": function(_record, index) {
                return index % 2 ? "bg-white" : "bg-gray-light"
              },
              pagination: {
                showTotal: function() {
                  return _vm.$t("lbl_total_items", {
                    total: _vm.formState.transferLines.length
                  })
                }
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3 text-right" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleBack } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
              ]),
              _vm.allowPrint
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingPrint },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }